import { LinkHTMLAttributes, MetaHTMLAttributes } from 'react';
import Head from 'next/head';

export interface MetadataProps {
  data: {
    title: string;
    description: string;
    keywords?: string;
    ogImage: string;
    alternate?: { hrefLang: string; href: string }[];
    ogType?: string;
    ogVideo?: string;
    twitterCard?: string;
    twitterImage?: string;
    imageSrc?: string;
    extraMeta?: MetaHTMLAttributes<HTMLMetaElement>[];
    extraLinks?: LinkHTMLAttributes<HTMLLinkElement>[];
  };
}

export const Metadata = ({
  data: {
    title,
    description,
    keywords,
    ogImage,
    alternate = [],
    ogType,
    ogVideo,
    twitterCard,
    twitterImage,
    imageSrc,
    extraMeta = [],
    extraLinks = [],
  },
}: MetadataProps) => {
  return (
    <Head>
      <title>{title}</title>
      {description && <meta name='description' content={description} />}
      <meta property='og:title' content={title} />
      <meta property='og:image' content={ogImage} />
      {description && <meta property='og:description' content={description} />}
      {keywords && <meta name='keywords' content={keywords} />}
      {alternate.map(({ hrefLang, href }) => (
        <link key={href} rel='alternate' hrefLang={hrefLang} href={href} />
      ))}
      {ogType && <meta property='og:type' content={ogType} />}
      {ogVideo && <meta property='og:video' content={ogVideo} />}
      {twitterCard && <meta name='twitter:card' content={twitterCard} />}
      {twitterImage && <meta name='twitter:image' content={twitterImage} />}
      {imageSrc && <link rel='image_src' href={imageSrc} />}
      {extraMeta.map((meta, i) => (
        <meta {...meta} key={i} />
      ))}
      {extraLinks.map((link, i) => (
        <link {...link} key={i} />
      ))}
    </Head>
  );
};

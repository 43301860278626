import { PropsWithChildren } from 'react';
import { cva, VariantProps } from 'class-variance-authority';

const cardTitle = cva(['font-medium leading-tight mt-3'], {
  variants: {
    size: {
      sm: ['text-base'],
      md: ['text-lg'],
      lg: ['text-xl'],
    },
    lineClamp: {
      1: ['line-clamp-1'],
      2: ['line-clamp-2'],
      3: ['line-clamp-3'],
      4: ['line-clamp-4'],
    },
    default: {
      size: 'md',
      lineClamp: 3,
    },
  },
});

export interface CardTitleProps
  extends PropsWithChildren,
    VariantProps<typeof cardTitle> {
  title?: string;
}

export const CardTitle = ({
  size,
  lineClamp,
  title,
  children,
}: CardTitleProps) => {
  return (
    <h3 className={cardTitle({ size, lineClamp })} title={title}>
      {children}
    </h3>
  );
};

import { useEffect, useState } from 'react';
import { useRouter } from 'next/router';
import { parseLocale, AD_ZONES } from '@afishauz/core/config';
import useTranslation from 'next-translate/useTranslation';
import { Link } from '@afishauz/core/widgets/link';
import { useAdvertisement } from '@afishauz/core/utils/ads';

const TWENTY_FIVE_SECONDS = 25 * 1000;

export const AsideBanners = () => {
  const { t } = useTranslation();
  const [shouldRefresh, setShouldRefresh] = useState(false);
  const router = useRouter();
  const locale = parseLocale(router.locale);
  const {
    data: topBannerData,
    update: updateTopBanner,
    ref: topBannerRef,
  } = useAdvertisement({
    zoneId: AD_ZONES[locale].DESKTOP_ASIDE,
  });
  const {
    data: bottomBannerData,
    update: updateBottomBanner,
    ref: bottomBannerRef,
  } = useAdvertisement({ zoneId: AD_ZONES[locale].DESKTOP_ASIDE_MINI });

  useEffect(() => {
    if (!shouldRefresh) {
      const timeoutId = setTimeout(() => {
        setShouldRefresh(true);
      }, TWENTY_FIVE_SECONDS);
      return () => {
        clearTimeout(timeoutId);
      };
    }
  }, [shouldRefresh]);

  useEffect(() => {
    const handleScroll = async () => {
      setShouldRefresh(false);
      if (shouldRefresh) {
        await updateTopBanner();
        await updateBottomBanner();
      }
    };
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [shouldRefresh, updateTopBanner, updateBottomBanner]);

  const placeholderLink = (
    <Link
      href='/reklama'
      className='text-xs text-gray-500 hover:text-gray-700 transition-colors hover:underline'>
      {t('elements.ad')}
    </Link>
  );

  return (
    <aside className='w-96'>
      <div className='sticky top-4 w-96 flex flex-col gap-y-6 justify-center items-center'>
        <div className='relative overflow-hidden bg-gray-50 rounded-md w-full flex justify-center items-center'>
          <div
            className='relative overflow-hidden w-[300px] flex justify-center items-center h-[500px]'
            ref={topBannerRef}>
            {topBannerData ? (
              <a
                href={topBannerData.clickUrl}
                target='_blank'
                rel='noreferrer'
                aria-label='ad'>
                <img
                  src={topBannerData.creativeUrl}
                  alt=''
                  width={topBannerData.width}
                  height={topBannerData.height}
                />
              </a>
            ) : (
              placeholderLink
            )}
          </div>
        </div>
        <div className='relative overflow-hidden bg-gray-50 rounded-md w-full flex justify-center items-center'>
          <div
            className='relative overflow-hidden w-[300px] flex justify-center items-center h-[120px]'
            ref={bottomBannerRef}>
            {bottomBannerData ? (
              <a
                href={bottomBannerData.clickUrl}
                target='_blank'
                rel='noreferrer'
                aria-label='ad'>
                <img
                  src={bottomBannerData.creativeUrl}
                  alt=''
                  width={bottomBannerData.width}
                  height={bottomBannerData.height}
                />
              </a>
            ) : (
              placeholderLink
            )}
          </div>
        </div>
        <ul className='flex gap-x-3 -mt-4'>
          <li>
            <Link
              href='/reklama'
              className='text-xs text-gray-500 hover:text-gray-700 transition-colors hover:underline'>
              {t('elements.aside_banner.ad')}
            </Link>
          </li>
          <li>
            <Link
              href='/contacts'
              className='text-xs text-gray-500 hover:text-gray-700 transition-colors hover:underline'>
              {t('elements.aside_banner.contacts')}
            </Link>
          </li>
          <li>
            <Link
              href='/about'
              className='text-xs text-gray-500 hover:text-gray-700 transition-colors hover:underline'>
              {t('elements.aside_banner.about')}
            </Link>
          </li>
          <li>
            <a
              href='https://docs.google.com/forms/d/e/1FAIpQLScRXQVAjS0rEzccf1V0cvjYc4WZpxDppyItd0GtFbbhvYVszg/viewform'
              target='_blank'
              rel='norefferer'
              className='text-xs text-gray-500 hover:text-gray-700 transition-colors hover:underline'>
              {t('elements.aside_banner.vacancies')}
            </a>
          </li>
        </ul>
      </div>
    </aside>
  );
};

import { PropsWithChildren, ReactNode } from 'react';
import { cva, VariantProps } from 'class-variance-authority';
import {
  ListItemContent,
  ListItemImage,
  ListItemText,
  ListItem as AfishaUzListItem,
} from '@afishauz/ui-kit/list';
import { CardBadge } from '@afishauz/ui-kit/card';
import { Link } from './link';
import { ImageWithFallback } from './image-with-fallback';

const image = cva([], {
  variants: {
    size: {
      sm: ['w-[57px]', 'min-w-[57px]', 'h-[38px]', 'min-h-[38px]'],
      md: ['w-24', 'min-w-[96px]', 'h-16', 'min-h-[64px]'],
      lg: ['w-[138px]', 'min-w-[138px]', 'h-23', 'min-h-[92px]'],
    },
  },
  defaultVariants: {
    size: 'md',
  },
});

export interface ListItemProps extends PropsWithChildren {
  link: {
    href: string;
    native?: boolean;
  };
  img?: {
    src?: string;
    alt?: string;
    size?: VariantProps<typeof image>['size'];
  };
  badges?: {
    position?: 'top-left' | 'top-right' | 'bottom-left' | 'bottom-right';
    component?: ReactNode;
    spacing?: 'sm' | 'md' | 'lg';
  }[];
}

export const ListItem = ({
  link,
  img = {},
  badges,
  children,
}: ListItemProps) => {
  const { src, alt, size } = img;
  return (
    <AfishaUzListItem>
      <Link href={link.href} nativeLink={link.native}>
        <ListItemContent>
          <ListItemText>{children}</ListItemText>
          <ListItemImage>
            <ImageWithFallback
              src={src}
              alt={alt}
              className={image({ size })}
            />
            {badges &&
              badges
                .filter(badge => !!badge.component)
                .map((badge, i) => (
                  <CardBadge
                    key={i}
                    position={badge.position}
                    spacing={badge.spacing}>
                    {badge.component}
                  </CardBadge>
                ))}
          </ListItemImage>
        </ListItemContent>
      </Link>
    </AfishaUzListItem>
  );
};

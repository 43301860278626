import { PropsWithChildren } from 'react';
import { cva, VariantProps } from 'class-variance-authority';

const grid = cva(['grid -mx-3.5 gap-x-6 gap-y-2 w-[calc(100%+28px)]'], {
  variants: {
    cols: {
      1: ['grid-cols-1'],
      2: ['grid-cols-2'],
      3: ['grid-cols-3'],
    },
  },
  defaultVariants: {
    cols: 2,
  },
});

export type ListItemsGridProps = PropsWithChildren & VariantProps<typeof grid>;

export const ListItemsGrid = ({ cols, children }: ListItemsGridProps) => {
  return <ul className={grid({ cols })}>{children}</ul>;
};

import { PropsWithChildren } from 'react';
import { Container } from '@afishauz/ui-kit/container';
import { AsideBanners } from '@/templates/shared/aside-banners';
import { cva, VariantProps } from 'class-variance-authority';

const container = cva([], {
  variants: {
    containerWidth: {
      wide: [
        'w-[--section-layout-container-wide-width]',
        'min-w-[--section-layout-container-wide-width]',
      ],
      narrow: [
        'w-[--section-layout-container-narrow-width]',
        'min-w-[--section-layout-container-narrow-width]',
      ],
    },
  },
  defaultVariants: {
    containerWidth: 'wide',
  },
});

interface Props extends PropsWithChildren, VariantProps<typeof container> {
  top?: number | string;
}

export const SectionLayout = ({
  top = 16,
  containerWidth,
  children,
}: Props) => {
  if (!children) {
    return null;
  }

  return (
    <Container>
      <div className='relative flex justify-between items-start'>
        <div className={container({ containerWidth })}>{children}</div>
        <div className='sticky w-96' style={{ top }}>
          <AsideBanners />
        </div>
      </div>
    </Container>
  );
};
